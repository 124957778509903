import React, { useState, useEffect, useRef, useContext, useMemo } from "react"
import PropTypes from "prop-types"
import "./InviteUser.scss"
import { Space, Tag, Tooltip, message } from "antd"
import { Customtagbar } from "../../../../config/global.style"
import initMatrix from "../../client/initMatrix"
import cons from "../../client/state/cons"
import * as roomActions from "../../client/action/room"
import { selectRoom } from "../../client/action/navigation"
import { hasDMWith, hasDevices, hasSMSWith } from "../../util/matrixUtil"
import RemoveCloseIcon from "../../../Icons/RemoveClose"
import Text from "../../atoms/text/Text"
import Button from "../../atoms/button/Button"
import IconButton from "../../atoms/button/IconButton"
import Spinner from "../../atoms/spinner/Spinner"
import Input from "../../atoms/input/Input"
import PopupWindow from "../../molecules/popup-window/PopupWindow"
import RoomTile from "../../molecules/room-tile/RoomTile"
import CrossIC from "../../public/res/ic/outlined/cross.svg"
import UserIC from "../../public/res/ic/outlined/user.svg"
import PlusIC from "../../public/res/ic/outlined/plus.svg"
import ChatWrapperContext from "../../ChatWrapper2/ChatWrapper2Context"
import { get } from "../../../../services/api"
import ScrollView from "../../atoms/scroll/ScrollView"
import SelectBar from "../../../Select/Select"
import ArrowSelectIconDark from "../../../Icons/ArrowSelectIconDark"
import { formatNumber } from "../../../../components/WebPhone2/utils/call"
import colorMXID from "../../util/colorMXID"
import Avatar from "../../atoms/avatar/Avatar"

function InviteUser({ isOpen, roomId, searchTerm, onRequestClose, isSMS }) {
  const [isSearching, updateIsSearching] = useState(false)
  const [searchQuery, updateSearchQuery] = useState({})
  const [allUsers, updateAllUsers] = useState([])
  const [users, updateUsers] = useState([])
  const [creatingDM, setCreatingDM] = useState(false)
  const { selectedUser, setSelectedUser } = useContext(ChatWrapperContext)

  const [procUsers, updateProcUsers] = useState(new Set()) // proc stands for processing.
  const [procUserError, updateUserProcError] = useState(new Map())
  const [createdDM, updateCreatedDM] = useState(new Map())
  const [roomIdToUserId, updateRoomIdToUserId] = useState(new Map())
  const [groupName, setGroupName] = useState("")
  const [invitedUserIds, updateInvitedUserIds] = useState(new Set())
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState("")
  const [messageApi, contextHolder] = message.useMessage()

  const usernameRef = useRef(null)
  const addPhoneNumberRef = useRef(null)

  const mx = initMatrix.matrixClient

  const getUsers = async (searchQuery = "") => {
    const response = isSMS
      ? await get(
          `/api/v1/auth/sms-mms-contact/?is_matrix=false&search=${searchQuery}`
        )
      : await get(`/api/v1/auth/contacts/?search=${searchQuery}`)
    const contacts = response.data.data.results
    const formattedUsers = contacts.map((user) => ({
      user_id: user.matrix_user_id,
      display_name: `${user.first_name} ${user.last_name}`,
      avatar_url: user.avatar,
      cell_phone: user.cell_phone
        ? user.cell_phone
        : user.work_phone
        ? user.work_phone
        : user.home_phone
        ? user.home_phone
        : ""
    }))
    updateAllUsers(formattedUsers)
    updateIsSearching(false)
  }

  useEffect(() => {
    if (isOpen) {
      updateIsSearching(true)
      getUsers()
    }
    return () => {
      setSelectedPhoneNumbers("")
      setGroupName("")
    }
  }, [isOpen])

  function removePhoneNumber(phoneNumbers, numberToRemove) {
    const escapedNumberToRemove = numberToRemove
      .replace(/^\+/, "")
      .replace(/^01/, "")
    const regex = new RegExp(
      `(,\\s*${escapedNumberToRemove}\\b|\\b${escapedNumberToRemove},\\s*|\\b${escapedNumberToRemove}\\b)`,
      "g"
    )
    return phoneNumbers
      .replace(regex, "") // Remove the number with possible surrounding commas
      .replace(/,\s*,/g, ",") // Replace any double commas with a single comma
      .replace(/^,\s*/, "") // Remove any leading comma
      .replace(/,\s*$/, "") // Remove any trailing comma
  }

  const handleDeleteUser = (userId) => {
    const updatedUsers = selectedUser.filter((user) =>
      isSMS ? user.cell_phone !== userId : user.user_id !== userId
    )
    setSelectedUser(updatedUsers)
    if (isSMS) {
      const updatedPhoneNumbers = removePhoneNumber(
        selectedPhoneNumbers,
        userId
      )
      setSelectedPhoneNumbers(updatedPhoneNumbers)
    }
  }
  function getMapCopy(myMap) {
    const newMap = new Map()
    myMap.forEach((data, key) => {
      newMap.set(key, data)
    })
    return newMap
  }
  function addUserToProc(userId) {
    procUsers.add(userId)
    updateProcUsers(new Set(Array.from(procUsers)))
  }
  function deleteUserFromProc(userId) {
    procUsers.delete(userId)
    updateProcUsers(new Set(Array.from(procUsers)))
  }

  function onDMCreated(newRoomId) {
    const myDMPartnerId = roomIdToUserId.get(newRoomId)
    if (typeof myDMPartnerId === "undefined") return

    createdDM.set(myDMPartnerId, newRoomId)
    roomIdToUserId.delete(newRoomId)

    deleteUserFromProc(myDMPartnerId)
    updateCreatedDM(getMapCopy(createdDM))
    updateRoomIdToUserId(getMapCopy(roomIdToUserId))
  }

  async function searchUser(username) {
    const inputUsername = username.trim()

    if (inputUsername === searchQuery.username) {
      return
    }

    if (isSearching || inputUsername === "") {
      updateUsers([])
      updateSearchQuery({})
      return
    }
    const isInputUserId =
      inputUsername[0] === "@" && inputUsername.indexOf(":") > 1
    updateIsSearching(true)
    updateSearchQuery({ username: inputUsername })

    if (isInputUserId) {
      try {
        const result = await mx.getProfileInfo(inputUsername)
        updateUsers([
          {
            user_id: inputUsername,
            display_name: result.displayname,
            avatar_url: result.avatar_url
          }
        ])
      } catch (e) {
        updateSearchQuery({ error: `${inputUsername} not found!` })
      }
    } else {
      try {
        const result = await mx.searchUserDirectory({
          term: inputUsername,
          limit: 20
        })

        if (result.results.length === 0) {
          updateSearchQuery({
            error: `No matches found for "${inputUsername}"!`
          })
          updateUsers([])
          updateIsSearching(false)
          return
        }
        updateUsers(result.results)
      } catch (e) {
        updateSearchQuery({ error: "Something went wrong!" })
      }
    }

    updateIsSearching(false)
  }

  async function createDM(users, isSMS = false, phoneNumber = "") {
    setCreatingDM(true)
    const selectedUserIds = users.map((user) => user.user_id)

    if (selectedUserIds.length === 1) {
      if (mx.getUserId() === selectedUserIds[0]) return
      const dmRoomId = hasDMWith(selectedUserIds[0])

      if (dmRoomId) {
        selectRoom(dmRoomId)
        setSelectedUser([])
        setCreatingDM(false)
        onRequestClose()
        return
      }
    }

    if (isSMS) {
      const smsRoomId = hasSMSWith(selectedPhoneNumbers)
      if (smsRoomId) {
        selectRoom(smsRoomId)
        setSelectedUser([])
        setCreatingDM(false)
        onRequestClose()
        return
      }
    }

    try {
      addUserToProc(selectedUserIds)
      procUserError.delete(selectedUserIds)
      updateUserProcError(getMapCopy(procUserError))
      const result = await roomActions.createDM(
        isSMS ? users : selectedUserIds,
        // await hasDevices(selectedUserIds),
        false,
        groupName,
        isSMS,
        phoneNumber,
        selectedPhoneNumbers
      )
      roomIdToUserId.set(result.room_id, selectedUserIds)
      updateRoomIdToUserId(getMapCopy(roomIdToUserId))
      setSelectedUser([])
      setSelectedPhoneNumbers("")
      selectRoom(result.room_id)
      setCreatingDM(false)
      onRequestClose()
    } catch (e) {
      deleteUserFromProc(selectedUserIds)
      if (typeof e.message === "string")
        procUserError.set(selectedUserIds, e.message)
      else procUserError.set(selectedUserIds, "Something went wrong!")
      updateUserProcError(getMapCopy(procUserError))
      setCreatingDM(false)
      setSelectedUser([])
      setSelectedPhoneNumbers("")
    }
  }

  async function inviteToRoom(userId) {
    if (typeof roomId === "undefined") return
    try {
      addUserToProc(userId)
      procUserError.delete(userId)
      updateUserProcError(getMapCopy(procUserError))

      await roomActions.invite(roomId, userId)

      invitedUserIds.add(userId)
      updateInvitedUserIds(new Set(Array.from(invitedUserIds)))
      deleteUserFromProc(userId)
    } catch (e) {
      deleteUserFromProc(userId)
      if (typeof e.message === "string") procUserError.set(userId, e.message)
      else procUserError.set(userId, "Something went wrong!")
      updateUserProcError(getMapCopy(procUserError))
    }
  }

  const handleAddUser = (user) => {
    setSelectedUser((prev) => [...prev, user])
    setSelectedPhoneNumbers((prev) => {
      if (prev) {
        return `${prev},${
          user.cell_phone.startsWith("+")
            ? user.cell_phone.slice(1)
            : user.cell_phone
        }`
      } else {
        return `${
          user.cell_phone.startsWith("+")
            ? user.cell_phone.slice(1)
            : user.cell_phone
        }`
      }
    })
  }

  function renderUserList() {
    const renderOptions = (user) => {
      const messageJSX = (message, isPositive) => (
        <Text variant="b2">
          <span
            style={{
              color: isPositive ? "var(--bg-positive)" : "var(--bg-negative)"
            }}
          >
            {message}
          </span>
        </Text>
      )

      if (mx.getUserId() === user.user_id) return null
      if (procUsers.has(user.user_id)) {
        return <Spinner size="small" />
      }
      if (createdDM.has(user.user_id)) {
        return (
          <Button
            onClick={() => {
              selectRoom(createdDM.get(user.user_id))
              onRequestClose()
            }}
          >
            Open
          </Button>
        )
      }
      if (invitedUserIds.has(user.user_id)) {
        return messageJSX("Invited", true)
      }
      if (typeof roomId === "string") {
        const member = mx.getRoom(roomId).getMember(user.user_id)
        if (member !== null) {
          const userMembership = member.membership
          switch (userMembership) {
            case "join":
              return messageJSX("Already joined", true)
            case "invite":
              return messageJSX("Already Invited", true)
            case "ban":
              return messageJSX("Banned", false)
            default:
          }
        }
      }

      const isUserAdded = selectedUser.some((selected) =>
        isSMS
          ? selected.cell_phone === user.cell_phone
          : selected.user_id === user.user_id
      )

      return typeof roomId === "string" ? (
        <Button
          disabled={isUserAdded}
          onClick={() => inviteToRoom(user.user_id)}
          variant="primary"
        >
          Invite
        </Button>
      ) : (
        <Button
          disabled={isUserAdded} // Disable if user is already added
          onClick={() => {
            handleAddUser(user)
          }}
          variant="primary"
        >
          Add
        </Button>
      )
    }

    const renderError = (userId) => {
      if (!procUserError.has(userId)) return null
      return (
        <Text variant="b2">
          <span style={{ color: "var(--bg-danger)" }}>
            {procUserError.get(userId)}
          </span>
        </Text>
      )
    }

    // if filtered users are not there, showing default api response of all users
    const showUser = users.length !== 0 ? users : allUsers
    return showUser.map((user) => {
      const userId = user.user_id
      const name =
        typeof user.display_name === "string" ? user.display_name : userId
      return (
        <RoomTile
          key={userId}
          avatarSrc={user.avatar_url}
          name={name}
          id={userId}
          options={renderOptions(user)}
          desc={renderError(userId)}
          phoneNumber={user.cell_phone}
        />
      )
    })
  }

  useEffect(() => {
    // if (isOpen && typeof searchTerm === "string") searchUser(searchTerm)
    if (isOpen && typeof searchTerm === "string") getUsers(searchTerm)
    return () => {
      updateIsSearching(false)
      updateSearchQuery({})
      updateUsers([])
      updateAllUsers([])
      updateProcUsers(new Set())
      updateUserProcError(new Map())
      updateCreatedDM(new Map())
      updateRoomIdToUserId(new Map())
      updateInvitedUserIds(new Set())
    }
  }, [isOpen, searchTerm])

  useEffect(() => {
    initMatrix.roomList.on(cons.events.roomList.ROOM_CREATED, onDMCreated)
    return () => {
      initMatrix.roomList.removeListener(
        cons.events.roomList.ROOM_CREATED,
        onDMCreated
      )
    }
  }, [isOpen, procUsers, createdDM, roomIdToUserId])
  const onGroupNameChange = async (e) => {
    setGroupName(e.target.value)
  }

  const { usersSmsNumbers } = useContext(ChatWrapperContext)
  const [phoneNumber, setPhoneNumber] = useState(usersSmsNumbers[0])
  const [addPhoneNumber, setAddPhoneNumber] = useState("")
  const options = usersSmsNumbers.map((number) => ({
    value: number,
    label: formatNumber(number)
  }))
  const formattedValue = useMemo(() => {
    const val = formatNumber(addPhoneNumber)
    if (!val) {
      return
    }
    return val
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPhoneNumber])
  const changeNumberHandler = (e) => {
    setPhoneNumber(e)
  }
  return (
    <PopupWindow
      isOpen={isOpen}
      title={
        typeof roomId === "string"
          ? `Invite to ${mx.getRoom(roomId).name}`
          : isSMS
          ? "SMS"
          : "Direct message"
      }
      contentOptions={
        <IconButton src={CrossIC} onClick={onRequestClose} tooltip="Close" />
      }
      onRequestClose={onRequestClose}
    >
      {contextHolder}
      <div className="invite-user">
        <form
          className="invite-user__form"
          onSubmit={(e) => {
            e.preventDefault()
            getUsers(usernameRef.current.value)
            // searchUser(usernameRef.current.value)
          }}
        >
          <Input value={searchTerm} forwardRef={usernameRef} label="Name" />
          <Button
            disabled={isSearching}
            iconSrc={UserIC}
            variant="primary"
            type="submit"
          >
            Search
          </Button>
        </form>
        {selectedUser?.length > 1 && (
          <div style={{ marginTop: "20px" }}>
            <Input
              label={isSMS ? "Group Name*" : "Group Name"}
              value={groupName}
              placeholder="Enter an optional name for this Group"
              onChange={onGroupNameChange}
              className="personsearch"
              maxLength={50}
            />
          </div>
        )}
        {isSMS && usersSmsNumbers.length > 0 && (
          <div className="phone-numbers">
            <p className=" input__label text-b2">Phone Numbers:</p>
            <SelectBar
              popupClassName="ant-select-dropdown-bordered"
              options={options}
              value={phoneNumber ? formatNumber(phoneNumber) : ""}
              onChange={changeNumberHandler}
              suffixIcon={<ArrowSelectIconDark />}
            />
          </div>
        )}
        {isSMS && (
          <div className="invite-user__form" style={{ "padding-top": "20px" }}>
            <Input
              value={formattedValue}
              forwardRef={addPhoneNumberRef}
              placeholder="Enter Number"
              onChange={(e) => {
                setAddPhoneNumber(e.target.value)
              }}
            />
            <Button
              disabled={isSearching}
              variant="primary"
              iconSrc={PlusIC}
              onClick={() => {
                const onlyDigitsRegex = /^[0-9]{10}$/

                if (onlyDigitsRegex.test(addPhoneNumber)) {
                  handleAddUser({
                    avatar_url: null,
                    cell_phone: addPhoneNumber,
                    display_name: addPhoneNumber,
                    user_id: "number"
                  })

                  setAddPhoneNumber("")
                  addPhoneNumberRef.current.value = ""
                } else {
                  messageApi.error(
                    "Phone number must be exactly 10 digits and contain only numbers."
                  )
                }
              }}
            >
              Add
            </Button>
          </div>
        )}
        {selectedUser.length !== 0 && (
          <div className="person-search-result">
            <Customtagbar>
              <Space className="space-wrap">
                {selectedUser.map((userData) => {
                  let name
                  if (typeof userData.display_name === "string") {
                    name = userData.display_name
                  } else {
                    name = userData.user_id
                  }
                  return (
                    <Tooltip
                      title={
                        isSMS
                          ? `${formatNumber(userData.cell_phone)}`
                          : `${userData.user_id}`
                      }
                      key={
                        isSMS
                          ? `${formatNumber(userData.cell_phone)}`
                          : `${userData.user_id}`
                      }
                    >
                      <Tag
                        closable
                        closeIcon={<RemoveCloseIcon />}
                        onClose={() =>
                          handleDeleteUser(
                            isSMS ? userData.cell_phone : userData.user_id
                          )
                        }
                      >
                        <span className="profilecover-icon-wrapper">
                          <Avatar
                            imageSrc={userData.avatar_url}
                            bgColor={colorMXID(userData.user_id)}
                            text={name}
                          />
                        </span>

                        <div className="data">
                          {userData.user_id == "number"
                            ? formatNumber(userData.cell_phone)
                            : userData.display_name}
                        </div>
                      </Tag>
                    </Tooltip>
                  )
                })}
              </Space>
            </Customtagbar>
          </div>
        )}
        <div className="invite-user__search-status">
          {typeof searchQuery.username !== "undefined" && isSearching && (
            <div className="flex--center">
              <Spinner size="small" />
              <Text variant="b2">{`Searching for user "${searchQuery.username}"...`}</Text>
            </div>
          )}
          {typeof searchQuery.username !== "undefined" && !isSearching && (
            <Text variant="b2">{`Search result for user "${searchQuery.username}"`}</Text>
          )}
          {searchQuery.error && (
            <Text className="invite-user__search-error" variant="b2">
              {searchQuery.error}
            </Text>
          )}
        </div>

        <ScrollView autoHide>
          {isSearching && typeof searchQuery.username === "undefined" && (
            <div className="flex--center">
              <Spinner size="small" />
            </div>
          )}

          {allUsers.length !== 0 && (
            <div className="invite-user__content">{renderUserList()}</div>
          )}
        </ScrollView>
      </div>
      {typeof roomId !== "string" && (
        <div className="start_conversation">
          <button
            className={`${
              (isSMS && selectedUser.length > 1 && groupName === ""
                ? true
                : false) || selectedUser.length === 0
                ? "disable_start_consversation_button"
                : "start_consversation_button"
            }`}
            disabled={
              isSMS
                ? selectedUser.length > 1 && groupName === ""
                  ? true
                  : false
                : selectedUser.length === 0
            }
            onClick={() => {
              let users
              if (isSMS) {
                users = [
                  ...selectedUser,
                  { user_id: import.meta.env?.VITE_SMS_BOT }
                ]
              }
              return createDM(isSMS ? users : selectedUser, isSMS, phoneNumber)
            }}
          >
            {creatingDM ? (
              <Spinner size="small" />
            ) : isSMS ? (
              "Send an SMS"
            ) : (
              "Start Conversation"
            )}
          </button>
        </div>
      )}
    </PopupWindow>
  )
}

InviteUser.defaultProps = {
  roomId: undefined,
  searchTerm: undefined
}

InviteUser.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  roomId: PropTypes.string,
  isSMS: PropTypes.bool,
  searchTerm: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired
}

export default InviteUser
